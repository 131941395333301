<template>
  <v-dialog fullscreen v-model="dialogPreview" @click:outside="$emit('closeDialog')">
    <v-card>
      <v-icon
          style="top:10px;right: 10px;z-index:99;position:absolute;"
          class="close__modal"
          @click="$emit('closeDialog')"
      >mdi-close</v-icon
      >
      <ConstructorPreview style="min-height:100vh;background-color:rgba(0,125,181,.12);" :id="+$route.params.id" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogPreview",
  props:{
    dialogPreview:{
      type:Boolean,
      default:false
    }
  },
  components:{
    ConstructorPreview:() => import("@/components/Constructor/ConstructorPreview")
  }
}
</script>

<style scoped>

</style>
